import { TseNumerical, TseSubmission } from '../../models/inputTypes/TseFields';

export const pointsInitialObject = { x: 1, y: 1 };

export const tseNumericalInitialState: TseNumerical = {
  project: '',
  simulationSetup: undefined,
  frame: 1,
  calculationFile: undefined,
  points: [pointsInitialObject],
  values: undefined,
  outputFileName: '',
};

export const tseSubmissionInitialState: TseSubmission = {
  simulationFile: '',
  vCpus: 1,
};
