import React, { FC, memo, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, Typography, styled } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInputWithLabel from '../../common/CustomInputWithLabel';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import { BtgBathymetric } from '../../../models/inputTypes/BtgFields';
import { FileType } from '../../../models/inputTypes/FileType';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { BtgSimulationPreparationResponse } from '../../../utils/simulationFileToState/btgSimulationPreparation';
import { useHistory, useParams } from 'react-router';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';
import { findFileType } from '../../../utils/functions/findFileType';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '800px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&>:first-child': {
    marginRight: theme.spacing(1),
  },
}));

type BathymetricInputsGroupProps = {
  inputState: BtgBathymetric;
  setInputState: (value: BtgBathymetric) => void;
  simulationChangeFile?: (value: BtgSimulationPreparationResponse, file?: FileType) => void;
};

const BtgBathymetricInputsGroup: FC<BathymetricInputsGroupProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { data, isFetching } = useGetProjectsQuery({});
  const history = useHistory();
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
      dataPoints: undefined,
      polygonsData: undefined,
      simulationSetup: undefined,
    });

    if (name && fileId) {
      history.push('/designTools/maris-btg');
    }
  };
  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name === 'closedPolygons' && (!event.target.value || event.target.value == '0')) {
      setInputState({ ...inputState, closedPolygons: +event.target.value, polygonsData: undefined });
      return;
    }
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              name={'project'}
              onChange={onInputChange}
              minWidth={'180px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'BTG'}
              modelType={ModelEnum.BTG}
              redirectLocation={'/designTools/maris-btg'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box sx={{ ...styles.flexStartBox }}>
          <Box>
            <LabelWrapper label={'Origin Coordinates (m)'}>
              <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                {/* <CustomInputWithLabel
                  min={0}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.coordX}
                  name={'coordX'}
                  onChange={onNumberChange}
                  label={'X'}
                /> */}
                <CustomBox>
                  <Typography variant={'subtitle1'}>X</Typography>
                  <CustomInput
                    maxWidth={'100px'}
                    min={0}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.coordX}
                    name={'coordX'}
                    onChange={onNumberChange}
                  />
                </CustomBox>
                <CustomBox>
                  <Typography variant={'subtitle1'}>Y</Typography>
                  <CustomInput
                    maxWidth={'100px'}
                    min={0}
                    step={'any'}
                    required
                    errorText={'Enter positive number'}
                    type={'number'}
                    value={inputState.coordY}
                    name={'coordY'}
                    onChange={onNumberChange}
                  />
                </CustomBox>
                {/* <CustomInputWithLabel
                  min={0}
                  step={'any'}
                  required
                  errorText={'Enter positive number'}
                  type={'number'}
                  value={inputState.coordY}
                  name={'coordY'}
                  onChange={onNumberChange}
                  label={'Y'}
                /> */}
              </Box>
            </LabelWrapper>
          </Box>
          <Box ml={4}>
            <LabelWrapper label={'Number of closed polygons'}>
              <CustomInput
                required
                min={0}
                max={findFileType(inputState.dataPoints ? inputState.dataPoints.fileName : '') == 'dxf' ? 0 : 100000}
                step={1}
                errorText={
                  findFileType(inputState.dataPoints ? inputState.dataPoints.fileName : '') == 'dxf'
                    ? 'Set to 0 if file is .dxf'
                    : 'Enter positive integer'
                }
                type={'number'}
                value={inputState.closedPolygons}
                name={'closedPolygons'}
                onChange={onNumberChange}
              />
            </LabelWrapper>
          </Box>
        </Box>
        <Box>
          <LabelWrapper label={'Insert closed polygons data'}>
            <ButtonFileDialog
              typePage={'BTG'}
              required
              dataFilesOnly
              disabled={inputState.closedPolygons == 0 || !inputState.project}
              projectId={inputState.project}
              file={inputState.polygonsData}
              setFile={(file) => onFileChange(file, 'polygonsData')}
            />
          </LabelWrapper>
        </Box>
      </Box>

      <Box mt={2} sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          {/*<Box sx={{ ...styles.flexStartBox }}>*/}
          {/*  <Box>*/}
          {/*    <LabelWrapper label={'Number of points'}>*/}
          {/*      <CustomInput*/}
          {/*        required*/}
          {/*        min={1}*/}
          {/*        step={1}*/}
          {/*        errorText={'Enter positive integer'}*/}
          {/*        type={'number'}*/}
          {/*        value={inputState.points}*/}
          {/*        name={'points'}*/}
          {/*        onChange={onNumberChange}*/}
          {/*      />*/}
          {/*    </LabelWrapper>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <LabelWrapper label={'Insert data points (.csv) or drawing (.dxf)'}>
            <ButtonFileDialog
              typePage={'BTG'}
              required
              dataDxfFilesOnly
              disabled={!inputState.project}
              projectId={inputState.project}
              file={inputState.dataPoints}
              setFile={(file) => onFileChange(file, 'dataPoints')}
            />
          </LabelWrapper>
        </Box>
      </Box>
    </>
  );
};

export default memo(BtgBathymetricInputsGroup);
