import React, { FC, memo, useMemo, useState } from 'react';
import { Box, Button, SelectChangeEvent, Typography, Stack, styled, IconButton, Grid } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import CustomInput from '../../custom/CustomInput';
import LabelWrapper from '../../common/LabelWrapper';
import { FileType } from '../../../models/inputTypes/FileType';
import { useGetProjectsQuery } from '../../../redux/RTK/queries/projectQuery';
import { useHistory, useParams } from 'react-router';
import { TseNumerical } from '../../../models/inputTypes/TseFields';
import SimulationFileContainer, {
  SimulationContainerSetter,
} from '../../common/fileComponents/SimulationFileContainer';
import { ModelEnum } from '../../../models/types/ModelEnum';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { pointsInitialObject } from '../../../utils/initialStates/tseInputState';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  spaceBetweenContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    maxWidth: '730px',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& >:nth-child(n)': {
      my: 1,
    },
  },
  flexStartBox: {
    '& > *': {
      my: 1,
      mr: { xs: 3, lg: 4 },
    },
    display: 'flex',
    justifyContent: 'flex-start',
  },
  coordinates: {
    '& >:nth-child(2)': {
      ml: 1,
    },
  },
} as const;

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&>:first-child': {
    marginRight: theme.spacing(1),
  },
}));

type TseNumericalInputsGroupProps = {
  inputState: TseNumerical;
  setInputState: React.Dispatch<React.SetStateAction<TseNumerical>>;
  simulationChangeFile?: (value: any, file?: FileType) => void;
};

const TseNumericalInputsGroup: FC<TseNumericalInputsGroupProps> = ({
  simulationChangeFile,
  inputState,
  setInputState,
}) => {
  const { data, isFetching } = useGetProjectsQuery({});
  const history = useHistory();
  const { name, fileId } = useParams<{ id?: string; name?: string; fileId?: string }>();
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const projectItems = useMemo(() => {
    if (data?.length) {
      return data.map((item, i) => (
        <CustomMenuItem key={i} value={item.id}>
          <Typography variant={'subtitle2'}>{item.name} </Typography>
        </CustomMenuItem>
      ));
    }
  }, [data]);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });

    if (name && fileId) {
      history.push('/designTools/maris-tse');
    }
  };

  const onSimulationFileChange = ({ loadedSimulation, file }: SimulationContainerSetter) => {
    if (simulationChangeFile && loadedSimulation && file) {
      return simulationChangeFile(loadedSimulation, file);
    }
    setInputState({ ...inputState, simulationSetup: undefined });
  };

  const isAddDisabled = useMemo(() => {
    return inputState.points.length >= 20;
  }, [inputState.points]);

  const handleAddFile = () => {
    const tempList = inputState.points.slice();
    tempList.push(pointsInitialObject);
    console.log(tempList);
    setInputState({ ...inputState, points: tempList });
  };

  const handleRemoveFile = (index: number) => {
    const pointList = inputState.points.slice();
    pointList.splice(index, 1);
    setInputState({ ...inputState, points: pointList });
  };

  const onDynamicInputChange = (index: number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newArray = inputState.points.slice();
    newArray[index] = { ...newArray[index], [e.target.name]: e.target.value };
    setInputState({ ...inputState, points: newArray });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    setInputState({ ...inputState, [fieldName]: file });
  };

  const tseInputs = useMemo(() => {
    const firstColumn = inputState.points.slice(0, 10);
    const secondColumn = inputState.points.slice(10);
    return (
      <>
        <Grid container spacing={6}>
          <Grid item>
            {firstColumn.map((item, i) => (
              <Box
                key={i}
                display={'flex'}
                alignItems={'center'}
                my={2}
                width={350}
                height={40}
                onMouseOver={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(-1)}
              >
                <Typography variant={'subtitle1'} mr={2}>
                  {i + 1}
                </Typography>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomBox>
                    <Typography variant={'subtitle1'}>X</Typography>
                    <CustomInput
                      maxWidth={'100px'}
                      min={0}
                      step={'any'}
                      required
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={item.x}
                      name={'x'}
                      onChange={(e) => onDynamicInputChange(i, e)}
                    />
                  </CustomBox>
                  <CustomBox>
                    <Typography variant={'subtitle1'}>Y</Typography>
                    <CustomInput
                      maxWidth={'100px'}
                      min={0}
                      step={'any'}
                      required
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={item.y}
                      name={'y'}
                      onChange={(e) => onDynamicInputChange(i, e)}
                    />
                  </CustomBox>
                </Box>
                {hoveredIndex === i && (
                  <Box>
                    <IconButton onClick={() => handleRemoveFile(i)} color={'info'}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </Grid>
          <Grid item>
            {secondColumn.map((item, i) => (
              <Box
                key={i + 10}
                display={'flex'}
                alignItems={'center'}
                my={2}
                width={350}
                height={40}
                onMouseOver={() => setHoveredIndex(i + 10)}
                onMouseLeave={() => setHoveredIndex(-1)}
              >
                <Typography variant={'subtitle1'} mr={2}>
                  {i + 11}
                </Typography>
                <Box sx={{ ...styles.flexStartBox, ...styles.coordinates }}>
                  <CustomBox>
                    <Typography variant={'subtitle1'}>X</Typography>
                    <CustomInput
                      maxWidth={'100px'}
                      min={0}
                      step={'any'}
                      required
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={item.x}
                      name={'x'}
                      onChange={(e) => onDynamicInputChange(i + 10, e)}
                    />
                  </CustomBox>
                  <CustomBox>
                    <Typography variant={'subtitle1'}>Y</Typography>
                    <CustomInput
                      maxWidth={'100px'}
                      min={0}
                      step={'any'}
                      required
                      errorText={'Enter positive number'}
                      type={'number'}
                      value={item.y}
                      name={'y'}
                      onChange={(e) => onDynamicInputChange(i + 10, e)}
                    />
                  </CustomBox>
                </Box>
                {hoveredIndex === i + 10 && (
                  <Box>
                    <IconButton onClick={() => handleRemoveFile(i + 10)} color={'info'}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </>
    );
  }, [inputState, hoveredIndex]);

  return (
    <>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Select project'}>
            <CustomSelect
              required
              value={inputState.project}
              name={'project'}
              onChange={onInputChange}
              minWidth={'132px'}
              isLoading={isFetching}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem value={''}>
                <Typography variant={'subtitle2'}>select project</Typography>
              </CustomMenuItem>
              {projectItems}
            </CustomSelect>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert simulation setup'}>
            <SimulationFileContainer
              typePage={'TSE'}
              modelType={ModelEnum.TSE}
              redirectLocation={'/designTools/maris-tse'}
              project={inputState.project}
              file={inputState.simulationSetup}
              setSimulationInputs={onSimulationFileChange}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box sx={{ ...styles.spaceBetweenContainer }}>
        <Box>
          <LabelWrapper label={'Frame'}>
            <CustomInput
              maxWidth={'57px'}
              required
              min={1}
              step={1}
              errorText={'Enter positive integer'}
              type={'number'}
              value={inputState.frame}
              name={'frame'}
              onChange={onInputChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Insert friction file'}>
            <ButtonFileDialog
              typePage={'PLOT'}
              dataFilesOnly
              required
              projectId={inputState.project}
              disabled={!inputState.project}
              file={inputState.calculationFile}
              setFile={(file) => onFileChange(file, 'calculationFile')}
            />
          </LabelWrapper>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography ml={3} variant={'subtitle2'}>
          Points
        </Typography>
        {tseInputs}
      </Box>
      <Box my={2}>
        <Button
          disabled={isAddDisabled}
          variant={'contained'}
          color={'success'}
          startIcon={<Add />}
          onClick={handleAddFile}
        >
          <Typography color={'info.contrastText'} variant={'subtitle2'}>
            Add points
          </Typography>
        </Button>
      </Box>
      <Stack>
        <LabelWrapper label={'Enter Output file name'}>
          <CustomInput
            type={'text'}
            required
            maxWidth={'200px'}
            placeholder={'Enter name'}
            value={inputState.outputFileName}
            name={'outputFileName'}
            onChange={onInputChange}
          />
        </LabelWrapper>
      </Stack>
    </>
  );
};

export default memo(TseNumericalInputsGroup);
