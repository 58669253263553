import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/structural/PageContainer';
import { Typography } from '@mui/material';
import BoxInputContainer from '../../components/custom/BoxInputContainer';
import CustomPrompt from '../../components/custom/CustomPrompt';
import { useCreateEviJobMutation } from '../../redux/RTK/mutations/jobsMutations';
import { useGetProjectsQuery } from '../../redux/RTK/queries/projectQuery';
import { useAddProjectFileMutation } from '../../redux/RTK/mutations/projectFileMutations';
import { FileType } from '../../models/inputTypes/FileType';
import { useAppDispatch } from '../../redux/store';
import TseNumericalInputsGroup from '../../components/groups/tse/TseNumericalInputsGroup';
import { TseNumerical } from '../../models/inputTypes/TseFields';
import { tseNumericalInitialState } from '../../utils/initialStates/tseInputState';
import { openWarningToast } from '../../redux/slices/appSlice';
import { eviPreparation } from '../../utils/modelPreparation/eviPreparation';
import { TseSimulationPreparationResponse } from '../../utils/simulationFileToState/tseSimulationPreparation';
import useSimulationPreparation from '../../utils/hooks/jsonParamHook';
import { ModelEnum } from '../../models/types/ModelEnum';
import PmsSubmissionInputsGroup from '../../components/groups/pms/PmsSubmissionInputsGroup';
import { PmsSubmission } from '../../models/inputTypes/PmsFields';
import { pmsSubmissionInitialState } from '../../utils/initialStates/pmsInputStates';

const MarisTsePage: FC = () => {
  const [createJob] = useCreateEviJobMutation();
  const { data } = useGetProjectsQuery({});
  const [uploadFile] = useAddProjectFileMutation();
  const dispatch = useAppDispatch();

  const [numericalState, setNumericalState] = useState<TseNumerical>(tseNumericalInitialState);
  const [submissionState, setSubmissionState] = useState<PmsSubmission>(pmsSubmissionInitialState);
  const [stateHasChanged, setStateHasChanged] = useState(false);
  const { preparedData, error, currentFile } = useSimulationPreparation(ModelEnum.EVI);

  useEffect(() => {
    if (preparedData && !error && currentFile) {
      onSimulationFileChange(preparedData, currentFile);
    }
  }, [preparedData, error, currentFile]);

  useEffect(() => {
    if (!stateHasChanged) {
      const hasNumericalChanged = numericalState != tseNumericalInitialState;
      const hasSubChanged = submissionState != pmsSubmissionInitialState;
      setStateHasChanged(hasNumericalChanged || hasSubChanged);
    }
  }, [numericalState, submissionState]);

  const selectedProjectName = useMemo(() => {
    if (data?.length) {
      const found = data.find((item) => item.id.toString() === numericalState.project.toString());
      return found?.name;
    }
  }, [numericalState.project]);

  const onSubmissionClick = (event: FormEvent<any>) => {
    event.preventDefault();
    console.log('click');
    // const { bedLevelList, sedimentList, fileType } = numericalState;
    // if (fileType == 1 && bedLevelList.length < 2) dispatch(openWarningToast('Bed level File rows should be > 1'));
    // if (fileType == 2 && sedimentList.length < 2) dispatch(openWarningToast('Sediment File rows should be > 1'));
    // const preparedValue = eviPreparation(numericalState, submissionState);
    // if (preparedValue) createJob(preparedValue);
  };

  const onSimulationSave = () => {
    console.log('save');
    // const preparedValue = eviPreparation(numericalState, submissionState);
    // if (preparedValue && numericalState.project && submissionState?.simulationFile) {
    //   uploadFile({
    //     id: numericalState.project,
    //     name: `${submissionState?.simulationFile}.json`,
    //     file: preparedValue,
    //   });
    // } else {
    //   dispatch(
    //     openWarningToast(
    //       !numericalState.project ? 'You have to select project first!' : 'simulation file name required!',
    //     ),
    //   );
    // }
  };

  const onSimulationFileChange = (value: TseSimulationPreparationResponse, file?: FileType) => {
    setNumericalState({ ...value.numeric, simulationSetup: file });
    setSubmissionState({ ...value.submission, simulationFile: file?.fileName?.split('.json')[0] ?? '' });
  };

  return (
    <PageContainer title={'NUMERICAL TOOLS'} subTitle={'Maris TSE'} projectName={selectedProjectName}>
      <form onSubmit={onSubmissionClick}>
        <CustomPrompt
          when={stateHasChanged}
          message={(params) =>
            params.pathname == '/designTools/maris-tse' ? true : 'Changes that you made may not be saved.'
          }
        />
        <Typography variant={'h4'}>Numerical Domain</Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <TseNumericalInputsGroup
            simulationChangeFile={onSimulationFileChange}
            inputState={numericalState}
            setInputState={setNumericalState}
          />
        </BoxInputContainer>

        <Typography mt={2} variant={'h4'}>
          Submission
        </Typography>
        <BoxInputContainer borderRadius={'4px'} mt={2}>
          <PmsSubmissionInputsGroup
            onSaveClick={onSimulationSave}
            inputState={submissionState}
            setInputState={setSubmissionState}
          />
        </BoxInputContainer>
      </form>
    </PageContainer>
  );
};

export default MarisTsePage;
