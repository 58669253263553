import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../pages/Home';
import Projects from '../pages/Projects';
import Jobs from '../pages/Jobs';
import Profile from '../pages/Profile';
import MarisBtgPage from '../pages/designTools/MarisBtgPage';
import MarisSrfPage from '../pages/designTools/MarisSrfPage';
import MarisPmsPage from '../pages/numericalModels/MarisPmsPage';
import MarisHmsPage from '../pages/numericalModels/MarisHmsPage';
import MarisHydPage from '../pages/numericalModels/MarisHydPage';
import MarisSdtPage from '../pages/numericalModels/MarisSdtPage';
import MarisPlotPage from '../pages/plotsAnimation/MarisPlotPage';
import MarisEviPage from '../pages/designTools/MarisEviPage';
import MarisTsePage from '../pages/designTools/MarisTsePage';

const RoutesWithLayout: FC = () => {
  return (
    <Route>
      <Switch>
        <Route exact path={'/'} render={() => <Home />} />
        <Route exact path={'/projects/:id?'} render={() => <Projects />} />
        <Route exact path={'/jobs'} render={() => <Jobs />} />
        <Route exact path={'/designTools/maris-btg/:id?/:name?/:fileId?'} render={() => <MarisBtgPage />} />
        <Route exact path={'/designTools/maris-srf/:id?/:name?/:fileId?'} render={() => <MarisSrfPage />} />
        <Route exact path={'/designTools/maris-evi/:id?/:name?/:fileId?'} render={() => <MarisEviPage />} />
        <Route exact path={'/designTools/maris-tse/:id?/:name?/:fileId?'} render={() => <MarisTsePage />} />

        <Route exact path={'/numerical-models/maris-pms/:id?/:name?/:fileId?'} render={() => <MarisPmsPage />} />
        <Route exact path={'/numerical-models/maris-hms/:id?/:name?/:fileId?'} render={() => <MarisHmsPage />} />
        <Route exact path={'/numerical-models/maris-hyd/:id?/:name?/:fileId?'} render={() => <MarisHydPage />} />
        <Route exact path={'/numerical-models/maris-sdt/:id?/:name?/:fileId?'} render={() => <MarisSdtPage />} />

        <Route exact path={'/plots-animation/maris-plot/:id?/:name?/:fileId?'} render={() => <MarisPlotPage />} />
        <Route exact path={'/profile'} render={() => <Profile />} />
        <Route exact path={'*'} render={() => <Redirect to={'/'} />} />
      </Switch>
    </Route>
  );
};

export default RoutesWithLayout;
